<template>
    <div class="body-container">
        <BannerSection title="Préstamo sobre vehículo"
                       subtitle="Tú solución financiera inmediata" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        ¿Te encuentras en una situación económica difícil y necesitas una
                        solución rápida sin sacrificar tu medio de transporte?
                        Ibancar te ofrece la solución perfecta con nuestro <span class="bold-text">préstamo sobre
                            vehículo</span>,
                        permitiéndote obtener el financiamiento que necesitas sin tener que renunciar a tu auto. Con
                        más de 6 años de experiencia satisfaciendo a clientes en España, Ibancar se enorgullece de
                        traer su experticia y compromiso a México, ofreciendo soluciones financieras adaptadas a tus
                        necesidades.
                    </p>

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo funciona el préstamo sobre vehículo?</h2>
                    </span>
                    <p class="mb-0">
                        El <span class="bold-text">préstamo sobre vehículo en Ibancar
                        </span>te ofrece una solución financiera rápida y segura, permitiéndote usar tu auto como
                        garantía sin renunciar a su uso. Este modelo está diseñado para quienes necesitan liquidez sin
                        sacrificar su movilidad, combinando la conveniencia de acceso a fondos inmediatos con la
                        libertad de seguir conduciendo tu vehículo. El proceso comienza con una evaluación justa de tu
                        coche para determinar el monto del préstamo, seguido de una solicitud sencilla y documentación
                        mínima. Una vez aprobado, el contrato se firma con claridad en los términos, y el dinero se
                        deposita directamente en tu cuenta, manteniendo todo el proceso transparente y sin
                        complicaciones. Con Ibancar, obtienes el efectivo que necesitas rápidamente, mientras tu vida
                        sigue en movimiento sin interrupciones. Este servicio destaca por su rapidez, flexibilidad y la
                        confianza de trabajar con una empresa que lleva más de 6 años satisfaciendo a clientes en
                        España, ahora disponible para ti en México.

                    </p>

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Cuáles son las ventajas de elegir Ibancar para tu préstamo?</h3>
                    </span>
                    <div>
                        <p class="mb-0 bold-text">
                            Proceso rápido y sencillo
                        </p>
                        <span>Entendemos la urgencia de tus necesidades, por eso nuestro proceso de solicitud y
                            aprobación es rápido y sin complicaciones. Obtén tu préstamo de forma eficiente para que
                            puedas concentrarte en lo que realmente importa.</span>
                    </div>
                    <br>

                    <div>
                        <p class="mb-0 bold-text">
                            Seguridad y confianza
                        </p>
                        <span>Tu tranquilidad es nuestra prioridad. Con la experiencia de Ibancar en España,
                            garantizamos un servicio transparente y confiable. Miles de clientes satisfechos son
                            testimonio de nuestro compromiso y calidad.</span>
                    </div>
                    <br>

                    <div>
                        <p class="mb-0 bold-text">
                            Condiciones flexibles
                        </p>
                        <span>Nos adaptamos a tu situación financiera ofreciendo planes de pago flexibles y tasas de
                            interés competitivas, asegurando que puedas cumplir con tus compromisos sin estrés</span>
                    </div>
                    <br>

                </div>
            </div>
        </div>
        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Por qué Ibancar es tu mejor opción?</h2>

                    </span>
                    <p class="mb-0">
                        En Ibancar, no solo te ofrecemos un <span class="bold-text">préstamo sobre vehículo
                        </span>; te brindamos una experiencia de préstamo diferente, centrada en la honestidad,
                        transparencia y adaptabilidad. Nuestra misión es proveerte de soluciones financieras sin
                        complicaciones, permitiéndote seguir adelante con tu vida sin interrupciones.


                        Nuestro equipo de expertos está aquí para guiarte en cada paso, asegurando que comprendas todas
                        las condiciones de tu préstamo y te sientas cómodo con tu decisión. Con más de 6 años de
                        experiencia en el mercado español, hemos perfeccionado nuestro servicio para ofrecerte lo mejor
                        en financiamiento vehicular.


                    </p>
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Da el primer paso hacia tu solución financiera</h2>
                    
                    </span>
                    <p class="mb-0">
                        Si necesitas asistencia económica y quieres mantener tu libertad de movilidad, el préstamo sobre
                        vehículo de Ibancar es la opción ideal para ti. No dejes que las dificultades económicas te
                        detengan. Contáctanos hoy y descubre cómo podemos ayudarte a alcanzar tus objetivos financieros
                        con facilidad y confianza.
                    </p>
                </div>
            </div>
        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosSobreVehiculo',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos sobre vehículo | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Tu carro puede ser la llave para el préstamo que buscas. Ofrecemos préstamos sobre vehículos con condiciones atractivas y sin complicaciones. Mantén el control de tu auto mientras solucionas tus necesidades financieras.'
                    },
                    {
                        name: 'keywords',
                        content: 'préstamo sobre vehículo'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamo-sobre-vehiculo', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>